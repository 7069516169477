import React from "react";

export const Footer = () => {
    return (
        <footer>
            <div className="fixed-footer">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <div>
                                Developed for <img src="/sake.svg" style={{width: 25}} alt="Sake icon"/> with
                                love <i
                                className="ri-heart-fill text-danger"/> !
                            </div>
                            <div className="d-flex align-items-center">
                                Happy Birthday
                                <i className="ri-cake-2-line text-primary display-5 ms-2"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}