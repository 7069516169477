export const JSON_DATA = [
    {
        quote: "Wisdom is the reward you get for a lifetime of listening when you'd have preferred to talk.",
        author: "Doug Larson",
        isFavorite: false,
        id: 0
    },
    {
        quote: "One of the most sincere forms of respect is actually listening to what another has to say.",
        author: "Bryant H. McGill",
        isFavorite: false,
        id: 1
    },
    {
        quote: "If you make listening and observation your occupation, you will gain much more than you can by talk.",
        author: "Robert Baden-Powell",
        isFavorite: false,
        id: 2
    },
    {
        quote: "If you make listening and observation your occupation, you will gain much more than you can by talk.",
        author: "Robert Baden-Powell",
        isFavorite: false,
        id: 3
    }
    ,
    {
        quote: "Listening is a magnetic and strange thing, a creative force. The friends who listen to us are the ones we move toward. When we are listened to, it creates us, makes us unfold and expand.",
        author: "Karl A. Menniger",
        isFavorite: false,
        id: 4
    }, {
        quote: "Most of the successful people I've known are the ones who do more listening than talking.",
        author: "Bernard Baruch",
        isFavorite: false,
        id: 5
    }, {
        quote: "Listening is being able to be changed by the other person.",
        author: "Alan Alda",
        isFavorite: false,
        id: 6
    }, {
        quote: "Everything in writing begins with language. Language begins with listening.",
        author: "Jeanette Winterson",
        isFavorite: false,
        id: 7
    }, {
        quote: "There is as much wisdom in listening as there is in speaking--and that goes for all relationships, not just romantic ones.",
        author: "Daniel Dae Kim",
        isFavorite: false,
        id: 8
    }, {
        quote: "The most important thing in communication is hearing what isn't said",
        author: "Peter Drucker",
        isFavorite: false,
        id: 9
    }, {
        quote: "When people talk, listen completely. Most people never listen.",
        author: "Ernest Hemingway",
        isFavorite: false,
        id: 10
    }, {
        quote: "Most people do not listen with the intent to understand; they listen with the intent to reply.",
        author: "Stephen R. Covey",
        isFavorite: false,
        id: 11
    }, {
        quote: "Friends are those rare people who ask how we are, and then wait to hear the answer.",
        author: "Ed Cunningham",
        isFavorite: false,
        id: 12
    }, {
        quote: "The art of conversation lies in listening.",
        author: "Malcom Forbes",
        isFavorite: false,
        id: 13
    }, {
        quote: "You cannot truly listen to anyone and do anything else at the same time.",
        author: "M. Scott Peck",
        isFavorite: false,
        id: 14
    }, {
        quote: "We have two ears and one tongue so that we would listen more and talk less.",
        author: "Diogenes",
        isFavorite: false,
        id: 15
    }, {
        quote: "Stories are a communal currency of humanity.",
        author: "Tahir Shah, in Arabian Nights",
        isFavorite: false,
        id: 16
    }, {
        quote: "Great stories happen to those who can tell them.",
        author: "Ira Glass",
        isFavorite: false,
        id: 17
    }, {
        quote: "The engineers of the future will be poets. ",
        author: "Terence McKenna",
        isFavorite: false,
        id: 18
    }, {
        quote: "The human species thinks in metaphors and learns through stories.",
        author: "Mary Catherine Bateson",
        isFavorite: false,
        id: 19
    }, {
        quote: "Sometimes reality is too complex. Stories give it form.",
        author: "Jean Luc Godard",
        isFavorite: false,
        id: 20
    }, {
        quote: "Story is a yearning meeting an obstacle.",
        author: "Robert Olen Butler",
        isFavorite: false,
        id: 21
    },
    {
        quote: "If you're going to have a story, have a big story, or none at all. ",
        author: "Joseph Campbell",
        isFavorite: false,
        id: 22
    },
    {
        quote: "Storytelling reveals meaning without committing the error of defining it.",
        author: "Hannah Arendt",
        isFavorite: false,
        id: 23
    }, {
        quote: "The stories we tell literally make the world. If you want to change the world, you need to change your story. This truth applies both to individuals and institutions.",
        author: "Michael Margolis",
        isFavorite: false,
        id: 24
    }, {
        quote: "Those who tell the stories rule the world.",
        author: "Hopi American Indian Proverb",
        isFavorite: false,
        id: 25
    }, {
        quote: "There is no greater agony than bearing an untold story inside you.",
        author: "Maya Angelou",
        isFavorite: false,
        id: 26
    }, {
        quote: "There's always room for a story that can transport people to another place.",
        author: "J.K. Rowling",
        isFavorite: false,
        id: 27
    }, {
        quote: "Enlightenment is the key to everything, and it is the key to intimacy, because it is the goal of true authenticity.",
        author: "Marianne Willliamson",
        isFavorite: false,
        id: 28
    }, {
        quote: "We need to find the courage to say no to the things and people that are not serving us if we want to rediscover ourselves and live our lives with authenticity.",
        author: "Barbara de Angelis",
        isFavorite: false,
        id: 29
    }, {
        quote: "I know of nothing more valuable, when it comes to the all-important virtue of authenticity, than simply being who you are.",
        author: "Charles R. Swindoll",
        isFavorite: false,
        id: 30
    }, {
        quote: "The keys to brand success are self-definition, transparency, authenticity and accountability.",
        author: "Simon Mainwaring",
        isFavorite: false,
        id: 31
    }, {
        quote: "Yes, in all my research, the greatest leaders looked inward and were able to tell a good story with authenticity and passion.",
        author: "Deepak Chopra",
        isFavorite: false,
        id: 32
    }, {
        quote: "Hard times arouse an instinctive desire for authenticity.",
        author: "Coco Chanel",
        isFavorite: false,
        id: 33
    }, {
        quote: "Always be a first-rate version of yourself and not a second-rate version of someone else.",
        author: "Judy Garland",
        isFavorite: false,
        id: 34
    }, {
        quote: "Be yourself--not your idea of what you think somebody else's idea of yourself should be.",
        author: "Henry David Thoreau",
        isFavorite: false,
        id: 35
    }, {
        quote: "Shine with all you have. When someone tries to blow you out, just take their oxygen and burn brighter.",
        author: "Katelyn S. Irons",
        isFavorite: false,
        id: 36
    }, {
        quote: "Live authentically. Why would you continue to compromise something that's beautiful to create something that is fake?",
        author: "Steve Maraboli",
        isFavorite: false,
        id: 37
    }, {
        quote: "Authentic brands don't emerge from marketing cubicles or advertising agencies. They emanate from everything the company does. . .",
        author: "Howard Schultz",
        isFavorite: false,
        id: 38
    }, {
        quote: "Authenticity requires a certain measure of vulnerability, transparency, and integrity",
        author: "Janet Louise Stepenson",
        isFavorite: false,
        id: 39
    }, {
        quote: "We have to dare to be ourselves, however frightening or strange that self may prove to be.",
        author: "May Sarton",
        isFavorite: false,
        id: 40
    }, {
        quote: "If you trade your authenticity for safety, you may experience the following: anxiety, depression, eating disorders, addiction, rage, blame, resentment, and inexplicable grief.",
        author: "May Sarton",
        isFavorite: false,
        id: 41
    }, {
        quote: "If you trade your authenticity for safety, you may experience the following: anxiety, depression, eating disorders, addiction, rage, blame, resentment, and inexplicable grief.",
        author: "Brene Brown",
        isFavorite: false,
        id: 42
    }, {
        quote: "I can be a better me than anyone can.",
        author: "Diana Ross",
        isFavorite: false,
        id: 43
    }, {
        quote: "There is no persuasiveness more effectual than the transparency of a single heart, of a sincere life.",
        author: "Joseph Barber Lightfoot",
        isFavorite: false,
        id: 44
    },
    {
        quote: "Transparency, honesty, kindness, good stewardship, even humor, work in businesses at all times.",
        author: "John Gerzema",
        isFavorite: false,
        id: 45
    },
    {
        quote: "A lack of transparency results in distrust and a deep sense of insecurity.",
        author: "Dalai Lama",
        isFavorite: false,
        id: 46
    },
    {
        quote: "I love when things are transparent, free and clear of all inhibition and judgment.",
        author: "Pharrell Williams",
        isFavorite: false,
        id: 47
    },
    {
        quote: "Eyes so transparent that through them the soul is seen.",
        author: "Theophile Gautier",
        isFavorite: false,
        id: 48
    },
    {
        quote: "Transparency is not the same as looking straight through a building: it's not just a physical idea, it's also an intellectual one.",
        author: "Helmut Jahn",
        isFavorite: false,
        id: 49
    },
    {
        quote: "I wish that every human life might be pure transparent freedom.",
        author: "Simone de Beauvoir",
        isFavorite: false,
        id: 50
    },
    {
        quote: "Truth never damages a cause that is just.",
        author: "Mahatma Gandhi",
        isFavorite: false,
        id: 51
    },
    {
        quote: "He had shown her all the workings of his soul, mistaking this for love.",
        author: "E.M. Forster",
        isFavorite: false,
        id: 52
    },
    {
        quote: "Our whole philosophy is one of transparency.",
        author: "Valerie Jarrett",
        isFavorite: false,
        id: 53
    },
    {
        quote: "A basic tenet of healthy democracy is open dialogue and transparency",
        author: "Peter Fenn",
        isFavorite: false,
        id: 54
    },
    {
        quote: "I just think we need more accountability and more transparency.",
        author: "John Thune",
        isFavorite: false,
        id: 55
    },
    {
        quote: "Honesty is the first chapter in the book of wisdom.",
        author: "Thomas Jefferson",
        isFavorite: false,
        id: 56
    },
    {
        quote: "Individual commitment to a group effort--that is what makes a team work, a company work, a society work, a civilization work.",
        author: "Vince Lombardi",
        isFavorite: false,
        id: 57
    },
    {
        quote: "Talent wins games, but teamwork and intelligence wins championships.",
        author: "Michael Jordan",
        isFavorite: false,
        id: 58
    },
    {
        quote: "Teamwork is the ability to work together toward a common vision. The ability to direct individual accomplishments toward organizational objectives. It is the fuel that allows common people to attain uncommon results.",
        author: "Andrew Carnegie",
        isFavorite: false,
        id: 59
    },
    {
        quote: "Alone we can do so little, together we can do so much.",
        author: "Helen Keller",
        isFavorite: false,
        id: 60
    },
    {
        quote: "Remember teamwork begins by building trust. And the only way to do that is to overcome our need for invulnerability.",
        author: "Patrick Lencioni",
        isFavorite: false,
        id: 61
    },
    {
        quote: "I invite everyone to choose forgiveness rather than division, teamwork over personal ambition.",
        author: "Jean-Francois Cope",
        isFavorite: false,
        id: 62
    }, {
        quote: "None of us is as smart as all of us.",
        author: "Ken Blanchard",
        isFavorite: false,
        id: 63
    },
    {
        quote: "Coming together is a beginning. Keeping together is progress. Working together is success.",
        author: "Henry Ford",
        isFavorite: false,
        id: 64
    },
    {
        quote: "If everyone is moving forward together, then success takes care of itself.",
        author: "Henry Ford",
        isFavorite: false,
        id: 65
    },
    {
        quote: "The strength of the team is each individual member. The strength of each member is the team.",
        author: "Phil Jackson",
        isFavorite: false,
        id: 66
    },
    {
        quote: "Collaboration allows teachers to capture each other's fund of collective intelligence.",
        author: "Mike Schmoker",
        isFavorite: false,
        id: 67
    },
    {
        quote: "It takes two flints to make a fire.",
        author: "Louisa May Alcott",
        isFavorite: false,
        id: 68
    },
    {
        quote: "Unity is strength. . . when there is teamwork and collaboration, wonderful things can be achieved.",
        author: "Mattie Stepanek",
        isFavorite: false,
        id: 69
    },
    {
        quote: "To me, teamwork is the beauty of our sport, where you have five acting as one. You become selfless.",
        author: "Mike Krzyzewski",
        isFavorite: false,
        id: 70
    },
    {
        quote: "The best teamwork comes from men who are working independently toward one goal in unison.",
        author: "James Cash Penney",
        isFavorite: false,
        id: 71
    },
    {
        quote: "I think that if you keep your eyes and your ears open and you are receptive to learning, there are skills you can get from any job at all.",
        author: "Cat Deeley",
        isFavorite: false,
        id: 72
    },
    {
        quote: "Seeking means: to have a goal; but finding means: to be free, to be receptive, to have no goal.",
        author: "Herman Hesse",
        isFavorite: false,
        id: 73
    },
    {
        quote: "It is impossible to withhold education from the receptive mind, as it is impossible to force it upon the unreasoning.",
        author: "Agnes Repplier",
        isFavorite: false,
        id: 74
    },
    {
        quote: "One of the things I've learned is to be receptive of feedback.",
        author: "Ben Silbermann",
        isFavorite: false,
        id: 75
    },
    {
        quote: "The best way to persuade people is with your ears--by listening to them.",
        author: "Dean Rusk",
        isFavorite: false,
        id: 76
    },
    {
        quote: "Confidence, like art, never comes from having all the answers; it comes from being open to all the questions.",
        author: "Earl Gray Stevens",
        isFavorite: false,
        id: 77
    }, {
        quote: "Life is 10% what happens to me and 90% how I react.",
        author: "John Maxwell",
        isFavorite: false,
        id: 78
    },
    {
        quote: "The pessimist complains about the wind; the optimist expects it to change; the realist adjusts the sails.",
        author: "William Arthur Ward",
        isFavorite: false,
        id: 79
    },
    {
        quote: "Relax & clear your mind if someone is speaking, so that you're receptive to what they're saying.",
        author: "Roger Ailes",
        isFavorite: false,
        id: 80
    },
    {
        quote: "The most difficult thing is the decision to act, the rest is merely tenacity.",
        author: "Emelia Earhart",
        isFavorite: false,
        id: 81
    },
    {
        quote: "Either you run the day, or they day runs you",
        author: "Jim Rohn",
        isFavorite: false,
        id: 82
    },
    {
        quote: "Tactics, fitness, stroke ability, adaptability, experience, and sportsmanship are all necessary for winning.",
        author: "Fred Perry",
        isFavorite: false,
        id: 83
    },
    {
        quote: "It is not the strongest or the most intelligent who will survive but those who can best manage change.",
        author: "Charles Darwin",
        isFavorite: false,
        id: 84
    },
    {
        quote: "Adaptability is about the powerful difference between adapting to cope and adapting to win.",
        author: "Max McKeown",
        isFavorite: false,
        id: 85
    },
    {
        quote: "The art of life is a constant readjustment to our surroundings.",
        author: "Kakuzo Okakaura",
        isFavorite: false,
        id: 86
    },
    {
        quote: "Adaptability is not imitation. It means power of resistance and assimilation.",
        author: "Mahatma Gandhi",
        isFavorite: false,
        id: 87
    },
    {
        quote: "You can't build an adaptable organization without adaptable people- and individuals change only when they have to, or when they want to.",
        author: "Gary Hamel",
        isFavorite: false,
        id: 88
    },
    {
        quote: "People will try to tell you that all the great opportunities have been snapped up. In reality, the world changes every second, blowing new opportunities in all directions, including yours.",
        author: "Ken Hakuta",
        isFavorite: false,
        id: 89
    },
    {
        quote: "Learn to adjust yourself to the conditions you have to endure, but make a point of trying to alter or correct conditions so that they are most favorable to you.",
        author: "William Frederick Book",
        isFavorite: false,
        id: 90
    },
    {
        quote: "All fixed set patterns are incapable of adaptability or pliability. The truth is outside of all fixed patterns.",
        author: "Bruce Lee",
        isFavorite: false,
        id: 91
    },
    {
        quote: "A wise man adapts himself to circumstances, as water shapes itself to the vessel that contains it.",
        author: "Chinese Proverb",
        isFavorite: false,
        id: 92
    },
    {
        quote: "Fall seven times and stand up eight.",
        author: "Japanese Proverb",
        isFavorite: false,
        id: 93
    },
    {
        quote: "When I let go of what I am, I become what I might be.",
        author: "Lao Tzu",
        isFavorite: false,
        id: 94
    },
    {
        quote: "You can't fall if you don't climb. But there's no joy in living your whole life on the ground.",
        author: "Unknown",
        isFavorite: false,
        id: 95
    },
    {
        quote: "Every great dream begins with a dreamer. Always remember, you have within you the strength, the patience, and the passion to reach for the stars to change the world.",
        author: "Harriet Tubman",
        isFavorite: false,
        id: 96
    },
    {
        quote: "There is no passion to be found playing small--in settling for a life that is less than the one you are capable of living.",
        author: "Nelson Mandela",
        isFavorite: false,
        id: 97
    },
    {
        quote: "Develop a passion for learning. If you do, you will never cease to grow.",
        author: "Anthony J. D'Angelo",
        isFavorite: false,
        id: 98
    },
    {
        quote: "Passion is energy. Feel the power that comes from focusing on what excites you.",
        author: "Oprah Winfrey",
        isFavorite: false,
        id: 99
    }, {
        quote: "If passion drives you, let reason hold the reins.",
        author: "Benjamin Franklin",
        isFavorite: false,
        id: 100
    },
    {quote: 'We must act out passion before we can feel it.', author: 'Jean-Paul Sartre', isFavorite: false, id: 101},
    {
        quote: 'It is obvious that we can no more explain a passion to a person who has never experienced it than we can explain light to the blind.',
        author: 'T. S. Eliot',
        isFavorite: false,
        id: 102
    },
    {
        quote: 'Nothing is as important as passion. No matter what you want to do with your life, be passionate.',
        author: 'Jon Bon Jovi',
        isFavorite: false,
        id: 103
    },
    {quote: 'You cant fake passion.', author: 'Barbara Corcoran', isFavorite: false, id: 104},
    {
        quote: 'You have to be burning with "an idea, or a problem, or a wrong that you want to right." If you are not passionate enough from the start, you will never stick it out.',
        author: 'Steve Jobs',
        isFavorite: false,
        id: 105
    },
    {
        quote: 'Yes, in all my research, the greatest leaders looked inward and were able to tell a good story with authenticity and passion.',
        author: 'Deepak Chopra',
        isFavorite: false,
        id: 106
    },
    {
        quote: 'If you feel like there is something out there that you are supposed to be doing, if you have a passion for it, then stop wishing and just do it.',
        author: 'Wanda Skyes',
        isFavorite: false,
        id: 107
    },
    {
        quote: 'If you dont love what you do, you wont do it with much conviction or passion.',
        author: 'Mia Hamm',
        isFavorite: false,
        id: 108
    },
    {
        quote: 'There is no end. There is no beginning. There is only the passion of life. There is no end. There is no beginning. There is only the passion of life.',
        author: 'Federico Fellini',
        isFavorite: false,
        id: 109
    },
    {
        quote: 'Any guy that is not working with the same amount of intensity and passion that I do, I dont want to know.',
        author: 'Zakk Wylde',
        isFavorite: false,
        id: 110
    },
    {
        quote: 'It is the souls duty to be loyal to its own desires. It must abandon itself to its master passion.',
        author: 'Rebecca West',
        isFavorite: false,
        id: 111
    },
    {
        isFavorite: false,
        id: 112,
        quote: 'The harder you fall, the higher you bounce.',
        author: null
    },
    {
        isFavorite: false,
        id: 113,
        quote: 'Trusting our intuition often saves us from disaster.',
        author: 'Anne Wilson Schaef'
    },
    {
        isFavorite: false,
        id: 114,
        quote: 'Truth is powerful and it prevails.',
        author: 'Sojourner Truth'
    },
    {
        isFavorite: false,
        id: 115,
        quote: 'Light tomorrow with today!',
        author: 'Elizabeth Browning'
    },
    {
        isFavorite: false,
        id: 116,
        quote: 'Silence is a fence around wisdom.',
        author: 'German proverb'
    },
    {
        isFavorite: false,
        id: 117,
        quote: 'Society develops wit, but its contemplation alone forms genius.',
        author: 'Madame de Stael'
    },
    {
        isFavorite: false,
        id: 118,
        quote: 'The simplest things are often the truest.',
        author: 'Richard Bach'
    },
    {
        isFavorite: false,
        id: 119,
        quote: 'Everyone smiles in the same language.',
        author: null
    },
    {
        isFavorite: false,
        id: 120,
        quote: 'Yesterday I dared to struggle. Today I dare to win.',
        author: 'Bernadette Devlin'
    },
    {
        isFavorite: false,
        id: 121,
        quote: 'No alibi will save you from accepting the responsibility.',
        author: 'Napoleon Hill'
    },
    {
        isFavorite: false,
        id: 122,
        quote: 'If you can dream it, you can do it.',
        author: 'Walt Disney'
    },
    {
        isFavorite: false,
        id: 123,
        quote: 'It is better to travel well than to arrive.',
        author: 'Buddha'
    },
    {
        isFavorite: false,
        id: 124,
        quote: "Life shrinks or expands in proportion to one's courage.",
        author: 'Anais Nin'
    },
    {
        isFavorite: false,
        id: 125,
        quote: 'You have to believe in yourself.',
        author: 'Sun Tzu'
    },
    {
        isFavorite: false,
        id: 126,
        quote: 'Our intention creates our reality.',
        author: 'Wayne Dyer'
    },
    {
        isFavorite: false,
        id: 127,
        quote: 'Silence is a true friend who never betrays.',
        author: 'Confucius'
    },
    {
        isFavorite: false,
        id: 128,
        quote: 'Character develops itself in the stream of life.',
        author: 'Johann Wolfgang von Goethe'
    },
    {
        isFavorite: false,
        id: 129,
        quote: 'From little acorns mighty oaks do grow.',
        author: 'American proverb'
    },
    {
        isFavorite: false,
        id: 130,
        quote: "You can't stop the waves, but you can learn to surf.",
        author: 'Jon Kabat-Zinn'
    },
    {
        isFavorite: false,
        id: 131,
        quote: 'Reality does not conform to the ideal, but confirms it.',
        author: 'Gustave Flaubert'
    },
    {
        isFavorite: false,
        id: 132,
        quote: 'Speak low, if you speak love.',
        author: 'William Shakespeare'
    },
    {
        isFavorite: false,
        id: 133,
        quote: 'A really great talent finds its happiness in execution.',
        author: 'Johann Wolfgang von Goethe'
    },
    {
        isFavorite: false,
        id: 134,
        quote: 'Reality leaves a lot to the imagination.',
        author: 'John Lennon'
    },
    {
        isFavorite: false,
        id: 135,
        quote: 'The greatest remedy for anger is delay.',
        author: 'Seneca'
    },
    {
        isFavorite: false,
        id: 136,
        quote: 'Growth itself contains the germ of happiness.',
        author: 'Pearl Buck'
    },
    {
        isFavorite: false,
        id: 137,
        quote: "You can do what's reasonable or you can decide what's possible.",
        author: null
    },
    {
        isFavorite: false,
        id: 138,
        quote: 'Nothing strengthens authority so much as silence.',
        author: 'Leonardo da Vinci'
    },
    {
        isFavorite: false,
        id: 139,
        quote: 'Wherever you go, go with all your heart.',
        author: 'Confucius'
    },
    {
        isFavorite: false,
        id: 140,
        quote: 'The only real valuable thing is intuition.',
        author: 'Albert Einstein'
    },
    {
        isFavorite: false,
        id: 141,
        quote: 'Good luck is another name for tenacity of purpose.',
        author: 'Ralph Emerson'
    },
    {
        isFavorite: false,
        id: 142,
        quote: 'Rainbows apologize for angry skies.',
        author: 'Sylvia Voirol'
    },
    {
        isFavorite: false,
        id: 143,
        quote: "Friendship isn't a big thing. It's a million little things.",
        author: null
    },
    {
        isFavorite: false,
        id: 144,
        quote: 'Time is the most valuable thing a man can spend.',
        author: 'Theophrastus'
    },
    {
        isFavorite: false,
        id: 145,
        quote: 'Whatever happens, take responsibility.',
        author: 'Tony Robbins'
    },
    {
        isFavorite: false,
        id: 146,
        quote: 'Experience is simply the name we give our mistakes.',
        author: 'Oscar Wilde'
    },
    {
        isFavorite: false,
        id: 147,
        quote: 'I think and that is all that I am.',
        author: 'Wayne Dyer'
    },
    {
        isFavorite: false,
        id: 148,
        quote: 'A good plan today is better than a perfect plan tomorrow.',
        author: null
    },
    {
        isFavorite: false,
        id: 149,
        quote: "If the shoe doesn't fit, must we change the foot?",
        author: 'Gloria Steinem'
    },
    {
        isFavorite: false,
        id: 150,
        quote: 'Each day provides its own gifts.',
        author: 'Marcus Aurelius'
    },
    {
        isFavorite: false,
        id: 151,
        quote: 'While we stop to think, we often miss our opportunity.',
        author: 'Publilius Syrus'
    },
    {
        isFavorite: false,
        id: 152,
        quote: "Life isn't about finding yourself. Life is about creating yourself.",
        author: 'Bernard Shaw'
    },
    {
        isFavorite: false,
        id: 153,
        quote: "To bring anything into your life, imagine that it's already there.",
        author: 'Richard Bach'
    },
    {
        isFavorite: false,
        id: 154,
        quote: 'Begin to weave and God will give you the thread.',
        author: 'German proverb'
    },
    {
        isFavorite: false,
        id: 155,
        quote: 'The more you know yourself, the more you forgive yourself.',
        author: 'Confucius'
    },
    {
        isFavorite: false,
        id: 156,
        quote: "Someone remembers, someone cares; your name is whispered in someone's prayers.",
        author: null
    },
    {
        isFavorite: false,
        id: 157,
        quote: 'Without faith, nothing is possible. With it, nothing is impossible.',
        author: 'Mary Bethune'
    },
    {
        isFavorite: false,
        id: 158,
        quote: 'Once we accept our limits, we go beyond them.',
        author: 'Albert Einstein'
    },
    {
        isFavorite: false,
        id: 159,
        quote: "Don't be pushed by your problems; be led by your dreams.",
        author: null
    },
    {
        isFavorite: false,
        id: 160,
        quote: 'Whatever we expect with confidence becomes our own self-fulfilling prophecy.',
        author: 'Brian Tracy'
    },
    {
        isFavorite: false,
        id: 161,
        quote: 'Everything you can imagine is real.',
        author: 'Pablo Picasso'
    },
    {
        isFavorite: false,
        id: 162,
        quote: 'Fear is a darkroom where negatives develop.',
        author: 'Usman Asif'
    },
    {
        isFavorite: false,
        id: 163,
        quote: 'The truest wisdom is a resolute determination.',
        author: 'Napoleon Bonaparte'
    },
    {
        isFavorite: false,
        id: 164,
        quote: 'Life is the flower for which love is the honey.',
        author: 'Victor Hugo'
    },
    {
        isFavorite: false,
        id: 165,
        quote: 'Freedom is the right to live as we wish.',
        author: 'Epictetus'
    },
    {
        isFavorite: false,
        id: 166,
        quote: 'Change your thoughts, change your life!',
        author: null
    },
    {
        isFavorite: false,
        id: 167,
        quote: "Never ignore a gut feeling, but never believe that it's enough.",
        author: 'Robert Heller'
    },
    {
        isFavorite: false,
        id: 168,
        quote: 'Loss is nothing else but change,and change is Natures delight.',
        author: 'Marcus Aurelius'
    },
    {
        isFavorite: false,
        id: 169,
        quote: 'Someone is special only if you tell them.',
        author: 'Byron Pulsifer'
    },
    {
        isFavorite: false,
        id: 170,
        quote: 'Today is the tomorrow you worried about yesterday.',
        author: null
    },
    {
        isFavorite: false,
        id: 171,
        quote: 'There is no way to happiness, happiness is the way.',
        author: 'Thich Nhat Hanh'
    },
    {
        isFavorite: false,
        id: 172,
        quote: 'The day always looks brighter from behind a smile.',
        author: null
    },
    {
        isFavorite: false,
        id: 173,
        quote: 'A stumble may prevent a fall.',
        author: null
    },
    {
        isFavorite: false,
        id: 174,
        quote: 'He who talks more is sooner exhausted.',
        author: 'Lao Tzu'
    },
    {
        isFavorite: false,
        id: 175,
        quote: 'He who is contented is rich.',
        author: 'Lao Tzu'
    },
    {
        isFavorite: false,
        id: 176,
        quote: 'What we achieve inwardly will change outer reality.',
        author: 'Plutarch'
    },
    {
        isFavorite: false,
        id: 177,
        quote: 'Our strength grows out of our weaknesses.',
        author: 'Ralph Waldo Emerson'
    },
    {
        isFavorite: false,
        id: 178,
        quote: 'We must become the change we want to see.',
        author: 'Mahatma Gandhi'
    },
    {
        isFavorite: false,
        id: 179,
        quote: 'Happiness is found in doing, not merely possessing.',
        author: 'Napoleon Hill'
    },
    {
        isFavorite: false,
        id: 180,
        quote: 'Put your future in good hands your own.',
        author: null
    },
    {
        isFavorite: false,
        id: 181,
        quote: 'We choose our destiny in the way we treat others.',
        author: 'Wit'
    },
    {
        isFavorite: false,
        id: 182,
        quote: 'No snowflake in an avalanche ever feels responsible.',
        author: 'Voltaire'
    },
    {
        isFavorite: false,
        id: 183,
        quote: 'Fortune favours the brave.',
        author: 'Virgil'
    },
    {
        isFavorite: false,
        id: 184,
        quote: 'I believe in one thing only, the power of human will.',
        author: 'Joseph Stalin'
    },
    {
        isFavorite: false,
        id: 185,
        quote: 'The best way out is always through.',
        author: 'Robert Frost'
    },
    {
        isFavorite: false,
        id: 186,
        quote: 'The mind unlearns with difficulty what it has long learned.',
        author: 'Seneca'
    },
    {
        isFavorite: false,
        id: 187,
        quote: 'I destroy my enemies when I make them my friends.',
        author: 'Abraham Lincoln'
    },
    {
        isFavorite: false,
        id: 188,
        quote: 'No garden is without its weeds.',
        author: 'Thomas Fuller'
    },
    {
        isFavorite: false,
        id: 189,
        quote: 'There is no failure except in no longer trying.',
        author: 'Elbert Hubbard'
    },
    {
        isFavorite: false,
        id: 190,
        quote: 'Kind words will unlock an iron door.',
        author: 'Turkish proverb'
    },
    {
        isFavorite: false,
        id: 191,
        quote: 'Problems are only opportunities with thorns on them.',
        author: 'Hugh Miller'
    },
    {
        isFavorite: false,
        id: 192,
        quote: 'Life is just a chance to grow a soul.',
        author: 'A. Powell Davies'
    },
    {
        isFavorite: false,
        id: 193,
        quote: 'Mountains cannot be surmounted except by winding paths.',
        author: 'Johann Wolfgang von Goethe'
    },
    {
        isFavorite: false,
        id: 194,
        quote: 'May our hearts garden of awakening bloom with hundreds of flowers.',
        author: 'Thich Nhat Hanh'
    },
    {
        isFavorite: false,
        id: 195,
        quote: 'Fortune befriends the bold.',
        author: 'John Dryden'
    },
    {
        isFavorite: false,
        id: 196,
        quote: 'Keep true to the dreams of thy youth.',
        author: 'Friedrich von Schiller'
    },
    {
        isFavorite: false,
        id: 197,
        quote: "You're never a loser until you quit trying.",
        author: 'Mike Ditka'
    },
    {
        isFavorite: false,
        id: 198,
        quote: 'Science is organized knowledge. Wisdom is organized life.',
        author: 'Immanuel Kant'
    },
    {
        isFavorite: false,
        id: 199,
        quote: 'Knowing is not enough; we must apply!',
        author: 'Johann Wolfgang von Goethe'
    },
    {
        isFavorite: false,
        id: 200,
        quote: 'Strong beliefs win strong men, and then make them stronger.',
        author: 'Richard Bach'
    },
    {
        isFavorite: false,
        id: 201,
        quote: 'Autumn is a second spring when every leaf is a flower.',
        author: 'Albert Camus'
    },
    {
        isFavorite: false,
        id: 202,
        quote: 'If you surrender to the wind, you can ride it.',
        author: 'Toni Morrison'
    },
    {
        isFavorite: false,
        id: 203,
        quote: 'Keep yourself to the sunshine and you cannot see the shadow.',
        author: 'Helen Keller'
    },
    {
        isFavorite: false,
        id: 204,
        quote: 'Write your plans in pencil and give God the eraser.',
        author: 'Paulo Coelho'
    },
    {
        isFavorite: false,
        id: 205,
        quote: 'Inspiration exists, but it has to find us working.',
        author: 'Pablo Picasso'
    },
    {
        isFavorite: false,
        id: 206,
        quote: 'Pick battles big enough to matter, small enough to win.',
        author: 'Jonathan Kozol'
    },
    {
        isFavorite: false,
        id: 207,
        quote: "Don't compromise yourself. You are all you've got.",
        author: 'Janis Joplin'
    },
    {
        isFavorite: false,
        id: 208,
        quote: 'A short saying oft contains much wisdom.',
        author: 'Sophocles'
    },
    {
        isFavorite: false,
        id: 209,
        quote: 'Difficulties are things that show a person what they are.',
        author: 'Epictetus'
    },
    {
        isFavorite: false,
        id: 210,
        quote: 'When you doubt your power, you give power to your doubt.',
        author: 'Honore de Balzac'
    },
    {
        isFavorite: false,
        id: 211,
        quote: 'The cause is hidden. The effect is visible to all.',
        author: 'Ovid'
    },
    {
        isFavorite: false,
        id: 212,
        quote: 'A prudent question is one half of wisdom.',
        author: 'Francis Bacon'
    },
    {
        isFavorite: false,
        id: 213,
        quote: 'The path to success is to take massive, determined action.',
        author: 'Tony Robbins'
    },
    {
        isFavorite: false,
        id: 214,
        quote: 'I allow my intuition to lead my path.',
        author: 'Manuel Puig'
    },
    {
        isFavorite: false,
        id: 215,
        quote: 'Nature takes away any faculty that is not used.',
        author: 'William R. Inge'
    },
    {
        isFavorite: false,
        id: 216,
        quote: 'If you wish to be a writer, write.',
        author: 'Epictetus'
    },
    {
        isFavorite: false,
        id: 217,
        quote: 'There is no way to prosperity, prosperity is the way.',
        author: 'Wayne Dyer'
    },
    {
        isFavorite: false,
        id: 218,
        quote: 'Either you run the day or the day runs you.',
        author: 'Jim Rohn'
    },
    {
        isFavorite: false,
        id: 219,
        quote: 'Better be ignorant of a matter than half know it.',
        author: 'Publilius Syrus'
    },
    {
        isFavorite: false,
        id: 220,
        quote: 'Follow your instincts. That is where true wisdom manifests itself.',
        author: 'Oprah Winfrey'
    },
    {
        isFavorite: false,
        id: 221,
        quote: 'There never was a good knife made of bad steel.',
        author: 'Benjamin Franklin'
    },
    {
        isFavorite: false,
        id: 222,
        quote: 'To accomplish great things, we must dream as well as act.',
        author: 'Anatole France'
    },
    {
        isFavorite: false,
        id: 223,
        quote: 'Patience is the companion of wisdom.',
        author: 'Saint Augustine'
    },
    {
        isFavorite: false,
        id: 224,
        quote: 'The mind is everything. What you think you become.',
        author: 'Buddha'
    },
    {
        isFavorite: false,
        id: 225,
        quote: 'To enjoy life, we must touch much of it lightly.',
        author: 'Voltaire'
    },
    {
        isFavorite: false,
        id: 226,
        quote: 'To fly, we have to have resistance.',
        author: 'Maya Lin'
    },
    {
        isFavorite: false,
        id: 227,
        quote: "What you see depends on what you're looking for.",
        author: null
    },
    {
        isFavorite: false,
        id: 228,
        quote: 'The heart has its reasons which reason knows not of.',
        author: 'Blaise Pascal'
    },
    {
        isFavorite: false,
        id: 229,
        quote: 'Be great in act, as you have been in thought.',
        author: 'William Shakespeare'
    },
    {
        isFavorite: false,
        id: 230,
        quote: 'Imagination rules the world.',
        author: 'Napoleon Bonaparte'
    },
    {
        isFavorite: false,
        id: 231,
        quote: 'Kind words do not cost much. Yet they accomplish much.',
        author: 'Blaise Pascal'
    },
    {
        isFavorite: false,
        id: 232,
        quote: 'There is no greater harm than that of time wasted.',
        author: 'Michelangelo'
    },
    {
        isFavorite: false,
        id: 233,
        quote: 'Intuition will tell the thinking mind where to look next.',
        author: 'Jonas Salk'
    },
    {
        isFavorite: false,
        id: 234,
        quote: 'Worry gives a small thing a big shadow.',
        author: null
    },
    {
        isFavorite: false,
        id: 235,
        quote: 'Fears are nothing more than a state of mind.',
        author: 'Napoleon Hill'
    },
    {
        isFavorite: false,
        id: 236,
        quote: 'The journey of a thousand miles begins with one step.',
        author: 'Lao Tzu'
    },
    {
        isFavorite: false,
        id: 237,
        quote: 'Efficiency is doing things right; effectiveness is doing the right things.',
        author: 'Peter Drucker'
    },
    {
        isFavorite: false,
        id: 238,
        quote: 'Blaze with the fire that is never extinguished.',
        author: 'Luisa Sigea'
    },
    {
        isFavorite: false,
        id: 239,
        quote: "Don't cry because it's over. Smile because it happened.",
        author: 'Dr. Seuss'
    },
    {
        isFavorite: false,
        id: 240,
        quote: 'No is easier to do. Yes is easier to say.',
        author: 'Jason Fried'
    },
    {
        isFavorite: false,
        id: 241,
        quote: 'To be wrong is nothing unless you continue to remember it.',
        author: 'Confucius'
    },
    {
        isFavorite: false,
        id: 242,
        quote: "Yesterdays home runs don't win today's games.",
        author: 'Babe Ruth'
    },
    {
        isFavorite: false,
        id: 243,
        quote: 'Silence is deep as Eternity, Speech is shallow as Time.',
        author: 'Carlyle'
    },
    {
        isFavorite: false,
        id: 244,
        quote: "Don't smother each other. No one can grow in the shade.",
        author: 'Leo F. Buscaglia'
    },
    {
        isFavorite: false,
        id: 245,
        quote: 'An ant on the move does more than a dozing ox',
        author: 'Lao Tzu'
    },
    {
        isFavorite: false,
        id: 246,
        quote: "You can't shake hands with a clenched fist.",
        author: 'Indira Gandhi'
    },
    {
        isFavorite: false,
        id: 247,
        quote: 'A good decision is based on knowledge and not on numbers.',
        author: 'Plato'
    },
    {
        isFavorite: false,
        id: 248,
        quote: 'The cautious seldom err.',
        author: 'Confucius'
    },
    {
        isFavorite: false,
        id: 249,
        quote: 'If there is no struggle, there is no progress.',
        author: 'Frederick Douglass'
    },
    {
        isFavorite: false,
        id: 250,
        quote: 'Where there is great love, there are always miracles.',
        author: 'Willa Cather'
    },
    {
        isFavorite: false,
        id: 251,
        quote: 'Time you enjoy wasting, was not wasted.',
        author: 'John Lennon'
    },
    {
        isFavorite: false,
        id: 252,
        quote: 'Every problem has a gift for you in its hands.',
        author: 'Richard Bach'
    },
    {
        isFavorite: false,
        id: 253,
        quote: 'Sadness flies away on the wings of time.',
        author: 'Jean de la Fontaine'
    },
    {
        isFavorite: false,
        id: 254,
        quote: 'I have often regretted my speech, never my silence.',
        author: 'Publilius Syrus'
    },
    {
        isFavorite: false,
        id: 255,
        quote: 'Never put off till tomorrow what you can do today.',
        author: 'Thomas Jefferson'
    },
    {
        isFavorite: false,
        id: 256,
        quote: 'Minds are like parachutes. They only function when open.',
        author: 'Thomas Dewar'
    },
    {
        isFavorite: false,
        id: 257,
        quote: 'If a man does his best, what else is there?',
        author: 'George Patton'
    },
    {
        isFavorite: false,
        id: 258,
        quote: 'The secret of success is constancy to purpose.',
        author: 'Benjamin Disraeli'
    },
    {
        isFavorite: false,
        id: 259,
        quote: 'Life is a progress, and not a station.',
        author: 'Ralph Emerson'
    },
    {
        isFavorite: false,
        id: 260,
        quote: 'All seasons are beautiful for the person who carries happiness within.',
        author: 'Horace Friess'
    },
    {
        isFavorite: false,
        id: 261,
        quote: 'To avoid criticism, do nothing, say nothing, be nothing.',
        author: 'Elbert Hubbard'
    },
    {
        isFavorite: false,
        id: 262,
        quote: 'All things change; nothing perishes.',
        author: 'Ovid'
    },
    {
        isFavorite: false,
        id: 263,
        quote: 'Absence makes the heart grow fonder.',
        author: 'Haynes Bayly'
    },
    {
        isFavorite: false,
        id: 264,
        quote: 'Imagination is the highest kite one can fly.',
        author: 'Lauren Bacall'
    },
    {
        isFavorite: false,
        id: 265,
        quote: 'The beginning of knowledge is the discovery of something we do not understand.',
        author: 'Frank Herbert'
    },
    {
        isFavorite: false,
        id: 266,
        quote: "Love doesn't make the world go round, love is what makes the ride worthwhile.",
        author: 'Elizabeth Browning'
    },
    {
        isFavorite: false,
        id: 267,
        quote: 'Whenever you have eliminated the impossible, whatever remains, however improbable, must be the truth.',
        author: 'Arthur Conan Doyle'
    },
    {
        isFavorite: false,
        id: 268,
        quote: 'Good timber does not grow with ease; the stronger the wind, the stronger the trees.',
        author: 'J. Willard Marriott'
    },
    {
        isFavorite: false,
        id: 269,
        quote: 'I believe that we are fundamentally the same and have the same basic potential.',
        author: 'Dalai Lama'
    },
    {
        isFavorite: false,
        id: 270,
        quote: 'The winds and waves are always on the side of the ablest navigators.',
        author: 'Edward Gibbon'
    },
    {
        isFavorite: false,
        id: 271,
        quote: 'The future belongs to those who believe in the beauty of their dreams.',
        author: 'Eleanor Roosevelt'
    },
    {
        isFavorite: false,
        id: 272,
        quote: 'To get something you never had, you have to do something you never did.',
        author: null
    },
    {
        isFavorite: false,
        id: 273,
        quote: "Be thankful when you don't know something for it gives you the opportunity to learn.",
        author: null
    },
    {
        isFavorite: false,
        id: 274,
        quote: 'Strength does not come from physical capacity. It comes from an indomitable will.',
        author: 'Mahatma Gandhi'
    },
    {
        isFavorite: false,
        id: 275,
        quote: 'Each misfortune you encounter will carry in it the seed of tomorrows good luck.',
        author: 'Og Mandino'
    },
    {
        isFavorite: false,
        id: 276,
        quote: 'To forgive is to set a prisoner free and realize that prisoner was you.',
        author: 'Lewis B. Smedes'
    },
    {
        isFavorite: false,
        id: 277,
        quote: "In separateness lies the world's great misery, in compassion lies the world's true strength.",
        author: 'Buddha'
    },
    {
        isFavorite: false,
        id: 278,
        quote: 'By believing passionately in something that does not yet exist, we create it.',
        author: 'Nikos Kazantzakis'
    },
    {
        isFavorite: false,
        id: 279,
        quote: 'Letting go is not the end of the world; it is the beginning of a new life.',
        author: null
    },
    {
        isFavorite: false,
        id: 280,
        quote: 'All the great performers I have worked with are fuelled by a personal dream.',
        author: 'John Eliot'
    },
    {
        isFavorite: false,
        id: 281,
        quote: 'One of the advantages of being disorderly is that one is constantly making exciting discoveries.',
        author: 'A. A. Milne'
    },
    {
        isFavorite: false,
        id: 282,
        quote: 'I never see what has been done; I only see what remains to be done.',
        author: 'Marie Curie'
    },
    {
        isFavorite: false,
        id: 283,
        quote: 'Begin at once to live and count each separate day as a separate life.',
        author: 'Seneca'
    },
    {
        isFavorite: false,
        id: 284,
        quote: "If you don't know where you are going, you will probably end up somewhere else.",
        author: 'Lawrence Peter'
    },
    {
        isFavorite: false,
        id: 285,
        quote: 'It is not so important to know everything as to appreciate what we learn.',
        author: 'Hannah More'
    },
    {
        isFavorite: false,
        id: 286,
        quote: 'The bird of paradise alights only upon the hand that does not grasp.',
        author: 'John Berry'
    },
    {
        isFavorite: false,
        id: 287,
        quote: 'Think as a wise man but communicate in the language of the people.',
        author: 'William Yeats'
    },
    {
        isFavorite: false,
        id: 288,
        quote: 'Practice yourself, for heavens sake in little things, and then proceed to greater.',
        author: 'Epictetus'
    },
    {
        isFavorite: false,
        id: 289,
        quote: 'If one does not know to which port is sailing, no wind is favorable.',
        author: 'Seneca'
    },
    {
        isFavorite: false,
        id: 290,
        quote: 'Our greatest glory is not in never failing but rising everytime we fall.',
        author: null
    },
    {
        isFavorite: false,
        id: 291,
        quote: 'Being right is highly overrated. Even a stopped clock is right twice a day.',
        author: null
    },
    {
        isFavorite: false,
        id: 292,
        quote: "To be upset over what you don't have is to waste what you do have.",
        author: 'Ken S. Keyes'
    },
    {
        isFavorite: false,
        id: 293,
        quote: 'If we did the things we are capable of, we would astound ourselves.',
        author: 'Thomas Edison'
    },
    {
        isFavorite: false,
        id: 294,
        quote: 'Nothing in life is to be feared. It is only to be understood.',
        author: 'Marie Curie'
    },
    {
        isFavorite: false,
        id: 295,
        quote: 'Successful people ask better questions, and as a result, they get better answers.',
        author: 'Tony Robbins'
    },
    {
        isFavorite: false,
        id: 296,
        quote: 'Love is not blind; it simply enables one to see things others fail to see.',
        author: null
    },
    {
        isFavorite: false,
        id: 297,
        quote: 'Life is a process. We are a process. The universe is a process.',
        author: 'Anne Schaef'
    },
    {
        isFavorite: false,
        id: 298,
        quote: 'I think somehow we learn who we really are and then live with that decision.',
        author: 'Eleanor Roosevelt'
    },
    {
        isFavorite: false,
        id: 299,
        quote: 'We learn what we have said from those who listen to our speaking.',
        author: 'Kenneth Patton'
    },
    {
        isFavorite: false,
        id: 300,
        quote: 'A little knowledge that acts is worth infinitely more than much knowledge that is idle.',
        author: 'Kahlil Gibran'
    },
    {
        isFavorite: false,
        id: 301,
        quote: 'If you get up one more time than you fall, you will make it through.',
        author: null
    },
    {
        isFavorite: false,
        id: 302,
        quote: 'The doors we open and close each day decide the lives we live.',
        author: 'Flora Whittemore'
    },
    {
        isFavorite: false,
        id: 303,
        quote: 'The worst bankrupt in the world is the person who has lost his enthusiasm.',
        author: 'H. W. Arnold'
    },
    {
        isFavorite: false,
        id: 304,
        quote: 'Happiness comes when your work and words are of benefit to yourself and others.',
        author: 'Buddha'
    },
    {
        isFavorite: false,
        id: 305,
        quote: "Don't focus on making the right decision, focus on making the decision the right one.",
        author: null
    },
    {
        isFavorite: false,
        id: 306,
        quote: 'Everything is perfect in the universe even your desire to improve it.',
        author: 'Wayne Dyer'
    },
    {
        isFavorite: false,
        id: 307,
        quote: 'The universe is full of magical things, patiently waiting for our wits to grow sharper.',
        author: 'Eden Phillpotts'
    },
    {
        isFavorite: false,
        id: 308,
        quote: 'Just as a candle cannot burn without fire, men cannot live without a spiritual life.',
        author: 'Buddha'
    },
    {
        isFavorite: false,
        id: 309,
        quote: 'A thing long expected takes the form of the unexpected when at last it comes.',
        author: 'Mark Twain'
    },
    {
        isFavorite: false,
        id: 310,
        quote: 'Action may not always bring happiness; but there is no happiness without action.',
        author: 'Benjamin Disraeli'
    },
    {
        isFavorite: false,
        id: 311,
        quote: "I don't believe in failure. It is not failure if you enjoyed the process.",
        author: 'Oprah Winfrey'
    },
    {
        isFavorite: false,
        id: 312,
        quote: 'What you do not want done to yourself, do not do to others.',
        author: 'Confucius'
    },
    {
        isFavorite: false,
        id: 313,
        quote: 'Short words are best and the old words when short are best of all.',
        author: 'Winston Churchill'
    },
    {
        isFavorite: false,
        id: 314,
        quote: 'If you light a lamp for somebody, it will also brighten your path.',
        author: 'Buddha'
    },
    {
        isFavorite: false,
        id: 315,
        quote: 'I have done my best: that is about all the philosophy of living one needs.',
        author: 'Lin-yutang'
    },
    {
        isFavorite: false,
        id: 316,
        quote: 'Through perseverance many people win success out of what seemed destined to be certain failure.',
        author: 'Benjamin Disraeli'
    },
    {
        isFavorite: false,
        id: 317,
        quote: 'Give thanks for the rain of life that propels us to reach new horizons.',
        author: 'Byron Pulsifer'
    },
    {
        isFavorite: false,
        id: 318,
        quote: 'Love is just a word until someone comes along and gives it meaning.',
        author: null
    },
    {
        isFavorite: false,
        id: 319,
        quote: 'We all have problems. The way we solve them is what makes us different.',
        author: null
    },
    {
        isFavorite: false,
        id: 320,
        quote: 'The secret to a rich life is to have more beginnings than endings.',
        author: 'Dave Weinbaum'
    },
    {
        isFavorite: false,
        id: 321,
        quote: 'It is only when the mind and character slumber that the dress can be seen.',
        author: 'Ralph Waldo Emerson'
    },
    {
        isFavorite: false,
        id: 322,
        quote: "If you don't like something, change it. If you can't change it, change your attitude.",
        author: 'Maya Angelou'
    },
    {
        isFavorite: false,
        id: 323,
        quote: 'Reviewing what you have learned and learning anew, you are fit to be a teacher.',
        author: 'Confucius'
    },
    {
        isFavorite: false,
        id: 324,
        quote: 'The world is a book, and those who do not travel read only a page.',
        author: 'Augustinus Sanctus'
    },
    {
        isFavorite: false,
        id: 325,
        quote: 'So long as a person is capable of self-renewal they are a living being.',
        author: 'Henri-Frederic Amiel'
    },
    {
        isFavorite: false,
        id: 326,
        quote: "I'm not afraid of storms, for I'm learning how to sail my ship.",
        author: 'Louisa Alcott'
    },
    {
        isFavorite: false,
        id: 327,
        quote: 'Think for yourselves and let others enjoy the privilege to do so too.',
        author: 'Voltaire'
    },
    {
        isFavorite: false,
        id: 328,
        quote: 'How we spend our days is, of course, how we spend our lives.',
        author: 'Annie Dillard'
    },
    {
        isFavorite: false,
        id: 329,
        quote: 'It has never been my object to record my dreams, just to realize them.',
        author: 'Man Ray'
    },
    {
        isFavorite: false,
        id: 330,
        quote: 'The most complicated achievements of thought are possible without the assistance of consciousness.',
        author: 'Sigmund Freud'
    },
    {
        isFavorite: false,
        id: 331,
        quote: "Be miserable. Or motivate yourself. Whatever has to be done, it's always your choice.",
        author: 'Wayne Dyer'
    },
    {
        isFavorite: false,
        id: 332,
        quote: 'Most great people have attained their greatest success just one step beyond their greatest failure.',
        author: 'Napoleon Hill'
    },
    {
        isFavorite: false,
        id: 333,
        quote: "If you think you can, you can. And if you think you can't, you're right.",
        author: 'Henry Ford'
    },
    {
        isFavorite: false,
        id: 334,
        quote: 'Better to have loved and lost, than to have never loved at all.',
        author: 'St. Augustine'
    },
    {
        isFavorite: false,
        id: 335,
        quote: 'Everyone thinks of changing the world, but no one thinks of changing himself.',
        author: 'Leo Tolstoy'
    },
    {
        isFavorite: false,
        id: 336,
        quote: 'The best way to pay for a lovely moment is to enjoy it.',
        author: 'Richard Bach'
    },
    {
        isFavorite: false,
        id: 337,
        quote: "You have enemies? Good. That means you've stood up for something, sometime in your life.",
        author: 'Winston Churchill'
    },
    {
        isFavorite: false,
        id: 338,
        quote: 'Slow down and everything you are chasing will come around and catch you.',
        author: 'John De Paola'
    },
    {
        isFavorite: false,
        id: 339,
        quote: 'Your worst enemy cannot harm you as much as your own unguarded thoughts.',
        author: 'Buddha'
    },
    {
        isFavorite: false,
        id: 340,
        quote: 'I always wanted to be somebody, but I should have been more specific.',
        author: 'Lily Tomlin'
    },
    {
        isFavorite: false,
        id: 341,
        quote: 'Yeah we all shine on, like the moon, and the stars, and the sun.',
        author: 'John Lennon'
    },
    {
        isFavorite: false,
        id: 342,
        quote: 'Knowledge is a process of piling up facts; wisdom lies in their simplification.',
        author: 'Martin Fischer'
    },
    {
        isFavorite: false,
        id: 343,
        quote: 'Life is like riding a bicycle. To keep your balance you must keep moving.',
        author: 'Albert Einstein'
    },
    {
        isFavorite: false,
        id: 344,
        quote: 'We should all be thankful for those people who rekindle the inner spirit.',
        author: 'Albert Schweitzer'
    },
    {
        isFavorite: false,
        id: 345,
        quote: 'Opportunity is missed by most because it is dressed in overalls and looks like work.',
        author: 'Thomas Edison'
    },
    {
        isFavorite: false,
        id: 346,
        quote: 'Feeling and longing are the motive forces behind all human endeavor and human creations.',
        author: 'Albert Einstein'
    },
    {
        isFavorite: false,
        id: 347,
        quote: 'In the end we retain from our studies only that which we practically apply.',
        author: 'Johann Wolfgang von Goethe'
    },
    {
        isFavorite: false,
        id: 348,
        quote: 'If you correct your mind, the rest of your life will fall into place.',
        author: 'Lao Tzu'
    },
    {
        isFavorite: false,
        id: 349,
        quote: 'The world makes way for the man who knows where he is going.',
        author: 'Ralph Emerson'
    },
    {
        isFavorite: false,
        id: 350,
        quote: 'When your desires are strong enough you will appear to possess superhuman powers to achieve.',
        author: 'Napoleon Hill'
    },
    {
        isFavorite: false,
        id: 351,
        quote: 'Patience and perseverance have a magical effect before which difficulties disappear and obstacles vanish.',
        author: 'John Adams'
    },
    {
        isFavorite: false,
        id: 352,
        quote: 'I cannot make my days longer so I strive to make them better.',
        author: 'Henry David Thoreau'
    },
    {
        isFavorite: false,
        id: 353,
        quote: 'Tension is who you think you should be. Relaxation is who you are.',
        author: 'Chinese proverb'
    },
    {
        isFavorite: false,
        id: 354,
        quote: 'Never bend your head. Always hold it high. Look the world right in the eye.',
        author: 'Helen Keller'
    },
    {
        isFavorite: false,
        id: 355,
        quote: 'One who gains strength by overcoming obstacles possesses the only strength which can overcome adversity.',
        author: 'Albert Schweitzer'
    },
    {
        isFavorite: false,
        id: 356,
        quote: 'We cannot do everything at once, but we can do something at once.',
        author: 'Calvin Coolidge'
    },
    {
        isFavorite: false,
        id: 357,
        quote: 'You have to do your own growing no matter how tall your grandfather was.',
        author: 'Abraham Lincoln'
    },
    {
        isFavorite: false,
        id: 358,
        quote: 'Invent your world. Surround yourself with people, color, sounds, and work that nourish you.',
        author: null
    },
    {
        isFavorite: false,
        id: 359,
        quote: 'It is fatal to enter any war without the will to win it.',
        author: 'General Douglas MacArthur'
    },
    {
        isFavorite: false,
        id: 360,
        quote: 'Be what you are. This is the first step toward becoming better than you are.',
        author: 'Julius Charles Hare'
    },
    {
        isFavorite: false,
        id: 361,
        quote: "There is nothing in a caterpillar that tells you it's going to be a butterfly.",
        author: 'Buckminster Fuller'
    },
    {
        isFavorite: false,
        id: 362,
        quote: 'Love and compassion open our own inner life, reducing stress, distrust and loneliness.',
        author: 'Dalai Lama'
    },
    {
        isFavorite: false,
        id: 363,
        quote: 'Ideals are an imaginative understanding of that which is desirable in that which is possible.',
        author: 'Walter Lippmann'
    },
    {
        isFavorite: false,
        id: 364,
        quote: 'The superior man is satisfied and composed; the mean man is always full of distress.',
        author: 'Confucius'
    },
    {
        isFavorite: false,
        id: 365,
        quote: "If you spend too much time thinking about a thing, you'll never get it done.",
        author: 'Bruce Lee'
    }
]