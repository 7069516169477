import {Container, Toast} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {JSON_DATA} from "../../data/data";
import moment from "moment";

const quoteList = JSON_DATA as any;

export interface IQuote {
    isFavorite: boolean,
    author: string,
    id: number,
    quote: string
}

export const Homepage = () => {
    const allQuotes = JSON.parse(localStorage.getItem("allQuotes") as string);
    const quoteNumber = Number(localStorage.getItem("quoteNumber"));
    const timeStamp = localStorage.getItem("timeStamp");
    const currDate = moment(new Date())
    const [show, setShow] = useState(false);
    const daysPast = currDate?.diff(moment(timeStamp), 'days');
    const [currentQuote, setCurrentQuote] = useState<IQuote>();
    const handleNextQuote = () => {
        if (daysPast >= 1) {
            const quoteToBe = (currentQuote?.id ?? 0) + 1;
            localStorage.setItem("quoteNumber", `${quoteToBe}`);
            localStorage.setItem("timeStamp", `${new Date()}`)
            setCurrentQuote(allQuotes[quoteToBe])
        } else {
            setShow(true)
        }
    }

    const handleFavoriteQuote = () => {
        const copy = [...allQuotes];
        const clickedIndex = copy.findIndex(item => item.id === currentQuote?.id);
        copy[clickedIndex] = {
            ...copy[clickedIndex],
            isFavorite: !copy[clickedIndex].isFavorite
        }
        localStorage.setItem("allQuotes", JSON.stringify(copy))
        setCurrentQuote(copy[clickedIndex])
    }

    useEffect(() => {
        if (!allQuotes?.length) {
            localStorage.setItem("allQuotes", JSON.stringify(quoteList))
            localStorage.setItem("quoteNumber", "0");
            localStorage.setItem("timeStamp", `${new Date()}`)
            setCurrentQuote(quoteList[0])
        }
    }, [allQuotes])

    useEffect(() => {
        const toSet = allQuotes ? allQuotes[quoteNumber ?? 0] : quoteList[quoteNumber ?? 0];
        console.log(toSet)
        setCurrentQuote(toSet)
    }, [])
    return (
        <Container className="my-3 centered-container d-flex flex-column">
            <Toast className="position-absolute" onClose={() => setShow(false)} show={show} delay={10000} autohide
                   style={{top: "1%"}}>
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">Error</strong>
                    <small>{moment(new Date()).fromNow()}</small>
                </Toast.Header>
                <Toast.Body>You are not able to change this quote yet :(</Toast.Body>
            </Toast>
            <h5>Day: {quoteNumber}</h5>
            <div className="card w-100">
                <div className="card-body">
                    <div className="pb-2">
                        {currentQuote?.quote}
                        <a href={`https://en.wikipedia.org/wiki/${currentQuote?.author}`}
                           className="text-decoration-none" rel="noreferrer"
                           target="_blank">
                            <p className="text-end text-muted mb-0 mt-3">
                                {currentQuote?.author}
                            </p>
                        </a>

                    </div>
                    <div className="border-top pt-2 text-center">
                        <i onClick={handleFavoriteQuote}
                           className={`ri-heart-fill text-${currentQuote?.isFavorite ? "danger" : "primary"} display-4`}/>
                    </div>
                </div>
            </div>
            <button onClick={handleNextQuote}
                    className="btn btn-primary btn-sm mt-4 text-white text-uppercase d-flex align-items-center">
                next quote <i className="ri-arrow-right-s-line"/>
            </button>
        </Container>
    )
}