import {Route, Routes} from 'react-router-dom';
import {Homepage} from "./homepage";
import {Favorites} from "./favorites/Favorites";
import {Header} from "../layout/Header";
import {Footer} from "../layout/Footer";

export function BaseRoutes() {
    return (
        <>
            <Header/>
            <Routes>
                <Route path="/" element={<Homepage/>}/>
                <Route path="/favorites" element={<Favorites/>}/>
            </Routes>
            <Footer/>
        </>
    )
}