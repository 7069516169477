import React from "react";
import {useNavigate} from "react-router-dom";

export const Header = () => {
    const navigate = useNavigate();
    const navigateHandler = (route: string) => {
        navigate(route);
    }
    return (
        <header>
            <div className="d-flex align-items-center justify-content-around box-shadow">
                <i className="ri-double-quotes-l text-white display-1" onClick={() => navigateHandler("/")}/>
                <i className="ri-heart-fill text-white display-1" onClick={() => navigateHandler("/favorites")}/>
            </div>
        </header>
    )
}