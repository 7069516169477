import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {IQuote} from "../homepage";

export const Favorites = () => {
    const [favoriteList, setFavoriteList] = useState<IQuote[]>([]);
    const allQuotes = JSON.parse(localStorage.getItem("allQuotes") as string) ?? []
    const allFavoritesQuotes = JSON.parse(localStorage.getItem("allQuotes") as string) ?? []
    const handleFavoriteQuote = (id: number) => {
        const copy = [...allQuotes];
        copy[id] = {
            ...copy[id],
            isFavorite: !copy[id]?.isFavorite
        }
        const filtered = allFavoritesQuotes?.filter((quote: IQuote) => quote.id !== id);
        setFavoriteList(filtered)
        localStorage.setItem("allQuotes", JSON.stringify(copy))
    }

    useEffect(() => {
        allFavoritesQuotes && setFavoriteList(allFavoritesQuotes?.filter((quote: IQuote) => quote.isFavorite));
    }, [JSON.stringify(allFavoritesQuotes)])
    return (
        <Container className="pb-5 mb-5">
            {!!favoriteList?.length ? favoriteList?.map(currentQuote => {
                return (
                    <div className="card w-100 mt-4" key={currentQuote?.id}>
                        <div className="card-body">
                            <div className="pb-2">
                                {currentQuote?.quote}
                                <p className="text-end text-muted mb-0 mt-3">
                                    {currentQuote?.author}
                                </p>
                            </div>
                            <div className="border-top pt-2 text-center">
                                <i onClick={() => handleFavoriteQuote(currentQuote?.id)}
                                   className={`ri-heart-fill text-${currentQuote?.isFavorite ? "danger" : "primary"} display-4`}/>
                            </div>
                        </div>
                    </div>
                )
            }) : <div>
                <div className="card mt-4">
                    <div className="card-body text-center text-muted">
                        List is empty.
                    </div>
                </div>
            </div>}

        </Container>
    )
}